import React from 'react'
import MainLayOut from "../components/MainLayout"
import Contact from '../components/Contact/Contact'
import Header from '../components/Header'

const ContactPage = () => {
  return (
      <>
        <Header />
            <Contact />
       
      </>
  )
}

export default ContactPage